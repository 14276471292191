.box {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 30vh;
    padding: 40px;
    width: 350px;
    height: 100%;
    background-color: #16181c;
    border-radius: 10px;
}

.inputApprovalCode {
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
}

.inputApprovalText {
    text-align: center;
    font-size: 12px;
}

.approvalMFA {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
}

.LoginInput {
    padding: 15px 0px !important;
    input {
        height: 0px;
        font-size: 13px;
    }
}

.logInButton {
    background-color: #3163c3 !important;
    margin-top: 10px !important;
}

.back {
    margin-top: 15px !important;
    color: #4284f4cc;
}
