.sendEmailText {
    font-size: 12px;
    margin: 0 auto;
}

.emailInput {
    padding: 10px 0px !important;
    input {
        height: 0px;
        font-size: 13px;
    }
}

.sendEmailToPassword {
    font-size: 12px;
    margin: 5px auto;
    cursor: pointer;
    &:hover {
        color: #2a55b7;
    }
    &:active {
        color: #537bd8;
    }
}

.sendButton {
    background-color: #3163c3 !important;
    margin-top: 20px !important;
}

.forgotPassword {
    margin-top: 15px !important;
    color: #4284f4cc;
}
