.balances {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0px;
}

.balancesTitle {
    font-size: 16px;
    font-weight: bold;
    padding-left: 16px;
    margin-bottom: 12px;
}

.balancesLists {
    flex: 1;
    background-color: #111217;
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}
.balancesBody {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        opacity: 0.9;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #aaa;
    }
}
.balancesHeader,
.balanceRow {
    margin: 12px;
    display: grid;
    grid-template-rows: 30px;
    font-size: 14px;
    grid-template-columns: repeat(4, minmax(100px, 1fr));
    grid-column-gap: 12px;
    & > div {
        text-align: right;
        padding: 0 12px;
    }
    & > div:first-of-type {
        text-align: left;
    }
}
.balancesHeader {
    color: #818286;
    font-weight: bold;
}
.balanceRow {
    font-size: 12px;
    background-color: #17181d;
    height: 50px;
    grid-template-rows: 50px;
    border-radius: 5px;
    margin-bottom: 16px;
    cursor: pointer;
    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    & > div:first-of-type {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    &:hover {
        background-color: #45464a;
    }
}
.assetCode {
    color: #808285;
    font-size: 12px;
}
.empty {
    color: #808285;
    font-size: 11px;
    padding: 12px 24px;
}
