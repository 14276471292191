.box {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 30vh;
    padding: 40px 40px 20px 40px;
    width: 350px;
    height: 100%;
    background-color: #16181c;
    border-radius: 10px;
}

.newPassword {
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
}

.newPasswordTitle {
    color: #808285;
    font-size: 12px;
    margin-bottom: 5px;
}

.passwordStrength {
    margin-top: 10px;
    font-size: 14px;
    color: #808285;
    font-weight: 700;
    .weak {
        color: #ff0000ae;
        font-weight: 400;
        font-size: 12px;
    }

    .good {
        color: #044721;
        font-weight: 400;
        font-size: 12px;
    }
}

.passwordInput {
    padding: 5px 0px !important;
    input {
        height: 0px;
        font-size: 13px;
    }
}

.resetPasswordInfo {
    color: #6f7683;
    font-size: 12px;
}

.sendButton {
    background-color: #3163c3 !important;
    margin-top: 20px !important;
}

.forgotPasswordBottomBox {
    display: flex;
    justify-content: space-around;
}

.BottomButton {
    flex: 1;
    margin-top: 20px !important;
    color: #4284f4cc;
}

.showPasswordIcon {
    font-size: 12px !important;
}
