.box {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 30vh;
    padding: 40px;
    width: 350px;
    height: 100%;
    background-color: #16181c;
    border-radius: 10px;
}

.logIn {
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
}

.LoginInput {
    padding: 5px 0px !important;
    input {
        height: 0px;
        font-size: 13px;
    }
}

.logInButton {
    background-color: #3163c3 !important;
    margin-top: 20px !important;
}

.forgotPassword {
    margin-top: 15px !important;
    color: #4284f4cc;
}

.showPasswordIcon {
    font-size: 12px !important;
}
