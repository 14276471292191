.accountPanel {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0px;
}

.accountPanelTitle {
    font-size: 16px;
    font-weight: bold;
    padding-left: 16px;
    margin-bottom: 12px;
}

.accountPanelLists {
    flex: 1;
    background-color: #111217;
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 16px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        opacity: 0.9;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #aaa;
    }
}

.accountItem {
    background-color: #17181d;
    font-size: 12px;
    width: 100%;
    min-height: 50px;
    border-radius: 5px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    &:hover {
        background-color: #45464a;
    }
}

.activeAccountItem {
    background-color: #0b59b9e0;
    &:hover {
        background-color: #0b59b9e0;
    }
}

.accountNumber {
    margin-right: 12px;
}
.accountName {
    text-align: right;
    flex: 1;
}
