.pageHeader {
    width: 100%;
    height: 40px;
    background-color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pageHeaderLeft {
    display: flex;
    align-items: center;
}
.logo {
    width: 24px;
    height: 24px;
    margin: 8px 16px;
}
.text {
  font-size: 12px;
  
}

.pageHeaderRight {
  display: flex;
  align-items: center;
  font-size: 12px;
  & > div{
    margin-left: 20px;
  }
}